import moment from 'moment';

export default class AuthUtil {
  /**
   * Return null or invalid message.
   *
   * @returns {string|null}
   */
  static validatePassword(val) {
    const passwordStatus = AuthUtil.checkPassword(val.password);
    let isValid = true;

    Object.keys(passwordStatus).forEach((check) => {
      // If isValid is false, don't need to check anything else - it's not valid
      if (isValid) {
        isValid = passwordStatus[check];
      }
    });

    if (isValid) {
      return null;
    }
    return 'Your password does not meet the minimum security qualifications, please try again.';
  }

  /**
   * Return object of password validation status.
   *
   * @returns {object} - contains validation status for the four requirements
   */
  static checkPassword(password) {
    return {
      length: !!password && password.length > 7,
      number: !!password && /\d/.test(password),
      lowercase: !!password && /[a-z]/.test(password),
      uppercase: !!password && /[A-Z]/.test(password),
    };
  }

  /**
   * Wether or not all password validations passed
   */
  static checkIsPasswordValid(password) {
    const { length, number, lowercase, uppercase } = AuthUtil.checkPassword(password);
    return length && number && lowercase && uppercase;
  }

  /**
   * Returns whether we are in dev environment
   * @returns bool
   */
  static isDevEnvironment() {
    return window.location.hostname.match(/^localhost$/) || window.location.hostname.match(/\.netlify\.app$/);
  }

  /**
   * Returns whether we are in BWB (Staging environment)
   * @returns bool
   */
  static isBWBEnvironment() {
    return Boolean(window.location.hostname.match(/\.blackwaterbay\.cybrary\.it$/));
  }

  /**
   * Returns whether or not the userMeta object provided is a new user
   * based upon the registration and last login time stamps.
   */
  static isNewUser(userMeta, lastLoginThreshold = 10000) {
    if (!userMeta || typeof userMeta !== 'object') {
      return false;
    }
    const { last_login_date, registered_at } = userMeta;
    // check against the registration date first for email logins
    const accountAge = moment(last_login_date) - moment(registered_at); // ms since registration
    // A user is new if they have registered within the given lastLoginThreshold time frame
    if (accountAge <= lastLoginThreshold) {
      return true;
    }
    return false;
  }

  static getEuCountries() {
    return ['AT', 'BE', 'BG', 'CY', 'CZ', 'DK', 'EE', 'FI', 'FR', 'DE', 'GR', 'HR', 'HU', 'IE', 'IT', 'LV', 'LT', 'LU', 'MT', 'NL', 'PL', 'PT', 'RO', 'SK', 'SI', 'ES', 'SE', 'GB'];
  }

  /**
   * Takes all user's emails and determines if they have a registered EDU/GOV/MIL email address
   * @param {Object} accounts - accounts.data object from profileStore
   */
  static isEduGovMilUser(accounts) {
    // If there are no accounts or no primary account, return false
    if (!accounts || !accounts.primary) {
      return false;
    }
    // init flat emails array with primary account
    const emails = [accounts.primary];
    // If there are other accounts, add them to the emails array
    if (accounts.other) {
      const otherAccounts = Object.values(accounts.other);
      otherAccounts.forEach((account) => emails.push(account));
    }
    return emails.some(({ email, verified }) => {
      if (!verified) {
        return false;
      }
      return email.match(/\.edu$|\.gov$|\.mil$|\.ac$|\.edu\.[a-z]{2}$|\.mil\.[a-z]{2}$|\.ac\.[a-z]{2}$|\.edu\.[a-z]{2}$|\.gov\.[a-z]{2}$|\.k12\.[a-z]{2}.us$/) !== null;
    });
  }
}
