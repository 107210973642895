import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import If from '../If/If';

const isExternal = (path) => {
  return /^http/.test(path);
};

const isApiCoursesLink = (path) => {
  return /^\/courses\/api/.test(path);
};

/**
 * Primary Link component for the application.
 * This component is used to wrap all links in the application.
 * It will add additional props to the link based on the props passed in.
 * It will forward all props to the link.
 * @param {string} props.to - The path to link to. (Optional if onClick is set)
 * @param {string} props.onClick - The function to call when the link is clicked. (Optional if to is set)
 * @param {boolean} props.active - Whether the link being created is the currently active link. (Optional)
 * @param {boolean} props.disabled - Whether the link is disabled. (Optional)
 * @returns
 */
function AddLink(props) {
  const forwardProps = useMemo(() => {
    const newProps = { ...props };
    // Set `aria-current` to 'page' if the link is active.
    // This is used for screen readers to indicate the current page.
    // We use props.active to determine if the link is active
    if (props?.active) {
      newProps['aria-current'] = 'page';
      // If the link is active, remove the active prop from the forwarded props object
      delete newProps.active;
    }
    return newProps;
  }, [props]);

  // If the link is not set, return a button or div
  if (!props.to || !props.to.length) {
    // If the onClick prop is set, return a button
    if (props.onClick) {
      return <button {...forwardProps}>{props.children}</button>;
    }
    // Otherwise, return a div
    return <div {...forwardProps}>{props.children}</div>;
  }

  // If the link is external, add screen reader text to indicate a new window will open
  if (isExternal(props.to)) {
    return (
      <a href={props.to} {...forwardProps}>
        {props.children}
        <If condition={props?.target === '_blank'}>
          <span className="sr-only">This link opens a new window</span>
        </If>
      </a>
    );
  }

  if (isApiCoursesLink(props.to)) {
    const path = props.to.replace('/courses/api', '');
    return (
      <a href={`${process.env.REACT_APP_COURSE_API_URL}${path}`} {...forwardProps}>
        {props.children}
      </a>
    );
  }

  return <Link {...forwardProps}>{props.children}</Link>;
}

export default AddLink;
