import React from 'react';
import { inject, observer } from 'mobx-react';
import queryString from 'query-string';
import Collapsible from '../../components/Collapsible/Collapsible';
import EmailManagement from './EmailManagement';
import NotificationPreferencesForm from './NotificationPreferencesForm';
import LanguagePreferencesForm from './LanguagePreferencesForm';
import NewslettersForm from './NewslettersForm';
import ExportAccount from './ExportAccount';
import DeleteAccount from './DeleteAccount';
import LearningScheduler from '../../components/LearningScheduler/LearningScheduler';
import ChevronDown from '../../Icons/ChevronDown';
import Divider from '../../components/Divider/Divider';
import FormatUtil from '../../utils/formatUtil';

const SettingsCollapsible = inject('authStore')(
  observer(
    class SettingsCollapsible extends React.Component {
      state = {
        currentIdx: null,
      };

      componentDidMount() {
        this.props.authStore.fireAttributionEvent();

        const queryParams = this.props.location.search ? queryString.parse(this.props.location.search) : {};
        if (queryParams.settings) {
          this.expandAndScroll(null, 0);
        } else if (queryParams['email-notifications']) {
          this.expandAndScroll(null, 2);
        } else if (queryParams['content-preferences']) {
          this.expandAndScroll(null, 4);
        } else if (queryParams['learning-schedule']) {
          this.expandAndScroll(null, 3);
        }
      }

      expandAndScroll = (e, idx) => {
        if (e) {
          e.preventDefault();
          e.stopPropagation();
        }
        this.setState({
          currentIdx: idx,
        });
        // Slight delay for rendering - Then scroll to section
        setTimeout(() => {
          this[`settings-container-${idx}`].scrollIntoView({
            behavior: 'smooth',
          });
        }, 400);
      };

      handleCollapsibleToggle = (idx) => {
        const { currentIdx } = this.state;
        if (currentIdx !== idx) {
          this.setState({
            currentIdx: idx,
          });
        } else {
          this.setState({
            currentIdx: null,
          });
        }
      };

      render() {
        const {
          userEmails,
          handleAddAdditionalEmail,
          handleRemoveAdditionalEmail,
          handleMakeEmailPrimary,
          handleVerificationAndForgotPassword,
          userNewEmailInput,
          setNewEmailInput,
          settingsData,
          preferencesData,
          addDefaultValues,
          handleOptInSubmit,
          handlePreferencesSubmit,
          handleInitiateDataExport,
          modalOpen,
          handleDeleteAccount,
          toggleConfirmationModal,
        } = this.props;
        const userEmailsCount = !!userEmails && userEmails.data && userEmails.data.other ? userEmails.data.other.length + 1 : 0;
        const pluralEmails = userEmailsCount > 1 ? 'es' : '';
        const panels = [
          {
            key: 1,
            title: 'Email Addresses',
            subtitle: 'Add or remove email addresses on your account and manage passwords',
            superSubtitle: userEmailsCount ? `${userEmailsCount} email address${pluralEmails}` : null,
            content: (
              <EmailManagement
                userEmails={userEmails}
                addDefaultValues={addDefaultValues}
                userNewEmailInput={userNewEmailInput}
                setNewEmailInput={setNewEmailInput}
                handleAddAdditionalEmail={handleAddAdditionalEmail}
                handleRemoveAdditionalEmail={handleRemoveAdditionalEmail}
                handleMakeEmailPrimary={handleMakeEmailPrimary}
                handleVerificationAndForgotPassword={handleVerificationAndForgotPassword}
              />
            ),
          },
          {
            key: 3,
            title: 'Email Subscription',
            subtitle: 'Update your email preferences',
            content: <NewslettersForm addDefaultValues={addDefaultValues} handleOptInSubmit={handleOptInSubmit} settingsData={settingsData} />,
          },
          {
            key: 4,
            title: 'Email Notifications',
            subtitle: 'Update your email notification preferences',
            content: <NotificationPreferencesForm addDefaultValues={addDefaultValues} handlePreferencesSubmit={handlePreferencesSubmit} preferencesData={preferencesData} />,
          },
          {
            key: 5,
            title: 'Learning Schedule',
            subtitle: (
              <span>
                <span className="block">Create a schedule to receive reminders and stay on track with your learning goals.</span>
              </span>
            ),
            content: <LearningScheduler expandAndScroll={this.expandAndScroll} />,
          },
          {
            key: 6,
            title: 'Language Preferences',
            subtitle: (
              <span>
                <span className="block">Select your preferred language for using the Cybrary app.</span>
              </span>
            ),
            content: <LanguagePreferencesForm />,
          },
          {
            key: 8,
            title: 'Export Account Data',
            subtitle: 'Export all activity and profile metadata for your account',
            content: <ExportAccount handleInitiateDataExport={handleInitiateDataExport} />,
          },
          {
            key: 9,
            title: 'Delete Your Account',
            subtitle: 'Close your Cybrary account',
            content: <DeleteAccount modalOpen={modalOpen} handleDeleteAccount={handleDeleteAccount} toggleConfirmationModal={toggleConfirmationModal} />,
          },
        ];

        const { currentIdx } = this.state;
        return (
          <div className="pt-12 settings-collapsible-container">
            {panels.map((panel, idx) => {
              const isOpen = currentIdx === idx;
              const collapsibleIdName = FormatUtil.lowerCaseHyphenText(panel.title || '');
              return (
                <div key={panel.title} className="setting-container">
                  <Collapsible
                    triggerTagName="div"
                    handleTriggerClick={() => this.handleCollapsibleToggle(idx)}
                    open={isOpen}
                    collapsibleId={`${collapsibleIdName}`}
                    trigger={
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          cursor: 'pointer',
                        }}
                        ref={(ref) => {
                          this[`settings-container-${idx}`] = ref;
                        }}
                      >
                        <div className="settings-collapse-content">
                          <h3 className="text-xl font-bold">{panel.title}</h3>
                          {panel.subtitle ? <p className="mb-4 text-sm text-gray-600">{panel.subtitle}</p> : null}
                          {panel.superSubtitle ? <p className="text-xs text-gray-600">{panel.superSubtitle}</p> : null}
                        </div>
                        <ChevronDown classes={`w-4 h-4 transition-transform ${isOpen && 'transform rotate-180'}`} />
                      </div>
                    }
                    transitionTime={250}
                    transitionCloseTime={250}
                  >
                    <div style={{ padding: 0, paddingTop: '1rem' }}>{panel.content}</div>
                  </Collapsible>
                  <Divider />
                </div>
              );
            })}
          </div>
        );
      }
    }
  )
);

export default SettingsCollapsible;
