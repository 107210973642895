import React, { useState, useEffect } from 'react';
import Bugsnag from '@bugsnag/js';
import queryString from 'query-string';
import { useParams, useNavigate } from 'react-router-dom';
import { inject, observer } from 'mobx-react';
import Title from '../../components/Title/Title';
import Modal from '../../components/Modal/Modal';
import Icon from '../../components/Icon/Icon';
import Table from '../../components/Table/Table';
import List, { ListItem } from '../../components/List/List';
import Loading from '../../components/Loading/Loading';
import AdminTable from '../../components/AdminTable/AdminTable';
import AddLink from '../../components/AddLink/AddLink';
import StyledError from '../../components/Error/StyledError';
import FormInviteModal from '../../components/Enterprise/FormInviteModal';
import MemberTableActions from '../../components/Enterprise/MemberTableActions';
import Container from '../../components/Container/Container';
import Checkbox from '../../components/FormFields/Checkbox';
import FormatUtil from '../../utils/formatUtil';
import Pagination from '../../components/Pagination/Pagination';
import Dropdown from '../../components/Dropdown/Dropdown';
import NewTeamPurchaseMessage from '../../components/Enterprise/NewTeamPurchaseMessage';
import './enterprise.css';
import Tooltip from '../../components/Tooltip/Tooltip';
import Header from '../../components/Header/Header';
import { HELP_DESK_LINKS, USERPILOT_EVENTS } from '../../constants';
import ActionUtil from '../../utils/actionsUtil';
import If from '../../components/If/If';
import UPTrigger from '../../components/UPTrigger/UPTrigger';

function TeamSizeLabels({ team }) {
  const membersLabel = `Team Member${team.member_count && team.member_count > 1 ? 's' : ''}`;
  return (
    <div>
      <div className="inline-block mr-0.5 mb-2 text-xs rounded-sm border border-black" style={{ padding: '.4em .8em' }}>
        {FormatUtil.formatNumbers(team.member_count)} {membersLabel}
      </div>
      {team.max_seats && team.active_seats ? (
        <div className="inline-block mb-2 text-xs text-green-500 rounded-sm border border-green-700" style={{ padding: '.4em .8em' }}>
          <span className="mr-1 text-green-700">
            {FormatUtil.formatNumbers(team.active_seats)} of {FormatUtil.formatNumbers(team.max_seats)} licenses used
          </span>
          <Tooltip
            triggerContent={<Icon className="inline-block w-4 h-4 text-green-700" name="information-circle" />}
            content="Contact a Customer Success Manager to manage your team licenses."
          />
        </div>
      ) : null}
    </div>
  );
}

function TeamDiscoveryDropdown({ editTeamSettings, autoInvite, domains }) {
  const [open, setOpen] = useState(false);

  const dropdownOptions = [
    {
      key: 'auto',
      text: 'Can join on their own',
      value: 'auto',
    },
    {
      key: 'invite',
      text: 'Must receive an invite',
      value: 'invite',
    },
  ];
  const hasDomainsConfigured = domains && domains.length;

  return (
    <div>
      Users with your{' '}
      <button style={{ cursor: 'pointer', textDecoration: 'underline', marginRight: '10px' }} onClick={() => setOpen(true)}>
        email domain
      </button>
      <Dropdown
        disabled={!hasDomainsConfigured}
        classes="w-64 inline-block"
        options={dropdownOptions}
        onChange={(data) => editTeamSettings({ auto_invite: data.value === 'auto' })}
        value={autoInvite ? 'auto' : 'invite'}
      />
      <Modal toggle={() => setOpen(false)} open={open} style={{ maxWidth: '500px' }} size="sm" paddingBottom="p-6" position="center" ariaLabelledBy="email-domains">
        <div className="px-6 pt-6">
          <h2 id="email-domains" className="mb-4 text-xl font-bold">
            Allowed Email Domains
          </h2>
          {hasDomainsConfigured ? (
            <>
              {domains.map((domain) => {
                if (!domain.enabled) {
                  return false;
                }
                return <div key={domain.domain} className="mt-2">{`@${domain.domain}`}</div>;
              })}
              <p>
                <em>To add additional email domains, please contact your Customer Success Manager</em>
              </p>
            </>
          ) : (
            <p>
              <em>You don&apos;t have any email domains setup yet. To help your teammates easily join Cybrary, please contact your Customer Success Manager</em>
            </p>
          )}
        </div>
      </Modal>
    </div>
  );
}

const InvitedTeamMembers = inject(
  'enterpriseStore',
  'commonStore'
)(
  observer(({ enterpriseStore, commonStore, loading, data, activePage, numPages }) => {
    const [invitedOpen, setInvitedOpen] = useState(false);
    const { orgId } = useParams();

    const deleteTeamInvitation = (inviteId) => {
      enterpriseStore
        .deleteTeamInvitation(orgId, inviteId)
        .then(() => {
          enterpriseStore.getInvitedTeamMembers(orgId);
          commonStore.triggerToast('success', {
            content: 'Successfully deleted invitation',
          });
        })
        .catch((error) => {
          Bugsnag.notify(error);
          commonStore.triggerToast('error', {
            content: 'Something went wrong. Unable to delete invitation',
          });
        });
    };

    const resendTeamInvitation = (inviteId) => {
      enterpriseStore
        .resendTeamInvitation(orgId, inviteId)
        .then(() => {
          commonStore.triggerToast('success', {
            content: 'Successfully resent invitation',
          });
        })
        .catch((error) => {
          Bugsnag.notify(error);
          commonStore.triggerToast('error', {
            content: 'Something went wrong. Unable to resend invitation',
          });
        });
    };

    const changePage = (pg) => {
      enterpriseStore.setTeamInviteQueryParams('activePg', pg);
      enterpriseStore.getInvitedTeamMembers(orgId);
    };

    if (!data || !data.totalRecords) {
      if (invitedOpen) {
        setInvitedOpen(false);
      }
      return null;
    }
    const listData = FormatUtil.convertTableDataToList(data.columns, data.tableData);
    const labelText = `${data.totalRecords} Pending Invite${data.totalRecords > 1 ? 's' : ''}`;
    return (
      <>
        <button onClick={() => setInvitedOpen(true)} className="mb-2 text-xs text-red-700 rounded-sm border border-red-700 sm:ml-0.5" style={{ padding: '.4em .8em' }}>
          {labelText}
        </button>
        <Modal open={invitedOpen} toggle={() => setInvitedOpen(false)} paddingBottom="p-4" position="center" size="lg" ariaLabelledBy="invited-users">
          <div className="pt-8 bg-white invited-members-list">
            <h2 id="invited-users" className="mb-2 text-xl font-bold">
              Invited Users
            </h2>
            <p>These users have been invited to your team, but have not accepted their invite.</p>
            {loading ? (
              <Container>
                <Loading message="Loading..." />
              </Container>
            ) : (
              <div className="flex flex-col">
                <List className="overflow-y-auto mt-4 max-h-96 border-gray-400">
                  {listData.map((item) => {
                    const roleMeta = FormatUtil.getTeamRoleMeta(item.role);
                    const roleClasses = `text-${roleMeta.color}-500 border-${roleMeta.color === 'grey' ? 'gray' : `${roleMeta.color}`}-600`;
                    return (
                      <ListItem key={item.id} className="py-2 border-t-xs border-gray-400">
                        <div className="float-right">
                          <Tooltip
                            omitTabIndex
                            triggerContent={
                              <button aria-label={`Resend invitation to ${item.email}`} onClick={() => resendTeamInvitation(item.id)}>
                                <Icon name="refresh" className="w-6 h-6 text-gray-500" />
                              </button>
                            }
                            content="Resend invitation"
                          />
                          <Tooltip
                            omitTabIndex
                            triggerContent={
                              <button aria-label={`Delete invitation for ${item.email}`} onClick={() => deleteTeamInvitation(item.id)}>
                                <Icon name="trash" className="w-6 h-6 text-gray-500" />
                              </button>
                            }
                            content="Delete invitation"
                          />
                        </div>
                        <div className="list-content">
                          {item.email} <div className={`border ${roleClasses} rounded-sm inline-block text-2xs ml-2 py-1 px-2`}>{FormatUtil.convertRoleName(item.role)}</div>
                          <p className="invite-date">Invited {FormatUtil.convertUtcToLocal(item.created_at).format('M/D/YYYY')}</p>
                        </div>
                      </ListItem>
                    );
                  })}
                </List>
                <div className="grow-0 mt-3">
                  <Pagination totalPages={numPages || 0} onPageChange={changePage} activePage={activePage} siblingRange={1} />
                </div>
              </div>
            )}
          </div>
        </Modal>
      </>
    );
  })
);

const OrganizationMembersTable = inject('enterpriseStore')(
  observer(({ enterpriseStore, toggleGroupsModal, groupsModalOpen }) => {
    const data = enterpriseStore.teamMemberTableData;
    const { orgId } = useParams();
    const isDemo = orgId === 'demo';

    const handleCheckBoxes = (e, { checked }, idx) => {
      enterpriseStore.setCheckboxState(enterpriseStore.teamMemberTableData, idx, checked);
    };

    const headerCheckBoxFunc = (e, { checked }) => {
      enterpriseStore.setAllSelectedRows('teamMemberTableData', checked);
    };

    const customTableCol = (inputData) => {
      return (
        <Table.Cell>
          <AddLink
            className={`text-cyb-pink-500 hover:text-black underline ${isDemo ? 'cursor-not-allowed' : ''}`}
            to={isDemo ? null : `/enterprise/${orgId}/organization/member/${inputData[0].value}/dashboard`}
          >
            View
            <span className="sr-only">{inputData[1].value || ''} member profile</span>
          </AddLink>
        </Table.Cell>
      );
    };

    const formatGroupsCol = (col) => {
      if (!col.value || !col.value.length) {
        return null;
      }
      const groups = col.value;

      if (groups.length === 1) {
        const group = groups[0];
        return (
          <Tooltip
            omitTabIndex
            triggerContent={
              <AddLink
                className={`text-cyb-pink-500 hover:text-black underline ${isDemo ? 'cursor-not-allowed' : ''}`}
                to={isDemo ? null : `/enterprise/${orgId}/organization/dashboard?group=${group.id}`}
              >
                {group.name}
              </AddLink>
            }
            content="View Dashboard"
          />
        );
      }
      return (
        <>
          <button
            onClick={() => toggleGroupsModal(true)}
            className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-black bg-gray-200 rounded-sm"
          >{`${groups.length} Groups`}</button>
          <Modal open={groupsModalOpen} toggle={() => toggleGroupsModal(false)} paddingBottom="p-2" className="view-groups" ariaLabelledBy="user-groups">
            <div className="p-6">
              <p id="user-groups">
                User is assigned to <strong>{groups.length} Groups</strong>
              </p>
              <div className="my-8 responsive-table-container">
                <Table className="responsive-table">
                  <Table.Body>
                    {groups.map((group) => {
                      const profileUrl = `/enterprise/${orgId}/organization/dashboard?group=${group.id}`;
                      return (
                        <Table.Row key={group.name}>
                          <Table.Cell className="w-3/5">{group.name}</Table.Cell>
                          <Table.Cell className="w-2/5 text-right">
                            <AddLink className={`text-cyb-pink-500 hover:text-black underline ${isDemo ? 'cursor-not-allowed' : ''}`} to={isDemo ? null : profileUrl}>
                              View Dashboard
                            </AddLink>
                          </Table.Cell>
                        </Table.Row>
                      );
                    })}
                  </Table.Body>
                </Table>
              </div>
            </div>
          </Modal>
        </>
      );
    };

    const breakEmails = (col) => {
      return FormatUtil.breakEmails(col.value);
    };

    if (data.loading) {
      return (
        <Container>
          <Loading message="Loading..." />
        </Container>
      );
    }
    if (data.error) {
      return (
        <Container>
          <StyledError error={data.error} />
        </Container>
      );
    }
    if (!data.totalRecords) {
      return (
        <p aria-live="polite" className="pb-8 no-results">
          No records found!
        </p>
      );
    }
    const { role, license, groupId, searchQuery } = enterpriseStore.teamMemberTableData.queryParams;
    return (
      <AdminTable
        /* Table Data */
        headings={data.tableHeadings}
        data={data.tableData}
        tableLoading={data.loading}
        tableError={data.error}
        displayCheckBox
        checkboxLabelCol="email"
        headerCheckBoxFunc={headerCheckBoxFunc}
        headerClickFunc={isDemo ? () => {} : (header) => enterpriseStore.sortTeamMemberTableData(header, orgId)}
        sortCol={data.queryParams.sortCol}
        sortDirection={data.queryParams.sortDirection}
        rowCheckBoxFunc={handleCheckBoxes}
        headerCheckBoxState={data.headerCheckBox}
        checkBoxesState={data.ckBoxesState}
        addCustomCol={[
          {
            method: customTableCol,
            position: data.tableHeadings ? data.tableHeadings.length : 9,
            header: 'Profile',
          },
        ]}
        pagNumPgs={data.pagPagesCount}
        pagChangePg={isDemo ? () => {} : (page) => enterpriseStore.setTeamMemberTablePage(page, orgId)}
        pagActivePg={data.queryParams.activePg}
        formatColumns={[
          {
            method: formatGroupsCol,
            colIdx: FormatUtil.getColIndex(data.tableHeadings, 'groups'),
          },
          {
            method: breakEmails,
            colIdx: FormatUtil.getColIndex(data.tableHeadings, 'name'),
          },
        ]}
        numOfResults={data.totalRecords}
        showResultsSummary={role || license || groupId || (searchQuery && searchQuery.length)}
      />
    );
  })
);

const OrganizationMembers = inject(
  'commonStore',
  'userStore',
  'enterpriseStore',
  'authStore'
)(
  observer(({ commonStore, userStore, enterpriseStore, authStore }) => {
    const { orgId } = useParams();
    const navigate = useNavigate();
    const isDemo = orgId === 'demo';
    const canManageAdmins = userStore?.team?.permissions?.canManageAdmins;

    const [state, setState] = useState({
      popUpOpen: false,
      modalOpen: false,
      groupsModalOpen: false,
      addMembersModalOpen: false,
    });

    const openModal = () => {
      enterpriseStore.getTeamInviteUrl(orgId);
      setState((prevState) => ({ ...prevState, modalOpen: true }));
    };

    const closeModal = () => {
      const queryParams = queryString.parse(window.location.search);
      if (queryParams.invite) {
        delete queryParams.invite;
        navigate({
          search: queryString.stringify(queryParams),
        });
      }
      setState((prevState) => ({ ...prevState, modalOpen: false }));
    };

    useEffect(() => {
      const init = (initOrgId) => {
        const queryParams = queryString.parse(window.location.search);
        if (queryParams.invite) {
          openModal();
        }

        enterpriseStore.setDefaultTeamMemberTableData();
        enterpriseStore.getTeamMemberTableData(initOrgId, false, false);
        enterpriseStore.setDefaultInvitedTeamMembers();
        enterpriseStore.getInvitedTeamMembers(initOrgId);
      };

      if (isDemo) {
        userStore.enterDemo();
        enterpriseStore.enterDemo();
      } else {
        userStore.setPreferredTeamInit('', orgId, init);
      }

      commonStore.setPageTitle(`Members${isDemo ? ' Demo' : ''} | Cybrary`);
      authStore.fireAttributionEvent();
      ActionUtil.scrollToTop();

      return () => {
        enterpriseStore.setDefaultTeamMemberTableData();
        enterpriseStore.setDefaultGroupList();
        enterpriseStore.setDefaultInvitedTeamMembers();

        if (isDemo) {
          userStore.exitDemo();
          enterpriseStore.exitDemo();
        }
      };
    }, [orgId, isDemo, userStore, enterpriseStore, authStore, commonStore]);

    const toggleGroupsModal = (toggle) => {
      setState((prevState) => ({
        ...prevState,
        groupsModalOpen: toggle,
      }));
    };

    const editTeamSettings = (setting) => {
      enterpriseStore
        .editOrgSettings(orgId, setting)
        .then(() => {
          commonStore.triggerToast('success', {
            content: 'Settings Updated',
          });
          userStore.loadAndSetTeamData(orgId);
        })
        .catch((e) => {
          commonStore.triggerToast('error', {
            errorCode: e.response.status,
          });
        });
    };

    const toggleAutoGrantSeatsConfirm = (e, { checked }) => {
      let confirmText;
      if (checked) {
        confirmText = 'Are you sure you want to enable auto assigning of licenses? Users invited to team will automatically be granted one of the allotted licenses for your team.';
      } else {
        confirmText =
          'Are you sure you want to disable auto assigning of licenses? ' +
          'Users invited to team will not automatically be granted one of the allotted licenses for your team. ' +
          'You will have to manually assign licenses to new users as needed.';
      }
      commonStore.triggerConfirm({
        content: confirmText,
        cancel: () => commonStore.resetConfirmState(),
        continue: () => {
          commonStore.resetConfirmState();
          editTeamSettings({ auto_grant_seats: checked });
        },
      });
    };

    if (!userStore.team) {
      return null;
    }

    const { logo_url, package_types, auto_grant_seats, domains } = userStore.team;
    const { invitedTeamMembers, getTeamInviteUrlByEmail, getInvitedTeamMembers, teamInviteUrl } = enterpriseStore;
    const { triggerToast } = commonStore;
    const topText = 'Invite new members to your Team!\nClick the icon to copy the url and send to the person you want to invite.';
    const bottomText = 'Once they click it, they will be prompted to create an account and will automatically join the team.\nZero hassle.';
    const autoAssignState = auto_grant_seats ? <span className="green">Enabled</span> : <span className="red">Disabled</span>;

    return (
      <Container size="lg" className="org-members">
        <div className="pt-16 pb-12 mb-4 border-b-xs border-gray-400">
          <div className="flex">
            <If condition={logo_url}>
              <div className="mr-4 text-center view-team-img">
                <img src={FormatUtil.correctImageUrl(logo_url)} alt="logo" className="max-w-[200px]" />
              </div>
            </If>
            <div className="flex-1">
              <div className="items-center mb-8 sm:flex">
                <Title title="Members" omitPadding wrapperClasses="grow" />
                <div className="flex items-start my-4">
                  <button
                    className="py-2.5 px-6 text-sm font-bold leading-5 text-center text-white hover:text-white bg-cyb-pink-500 hover:bg-pink-600 rounded-sm"
                    onClick={() => openModal()}
                  >
                    + Invite to Team
                  </button>
                  <UPTrigger guideId={USERPILOT_EVENTS.INVITE_TO_TEAM_BUTTON} className="-mt-5 -ml-1" />
                </div>
              </div>
              <div>
                <div className="sm:flex">
                  <TeamSizeLabels team={userStore.team} />
                  <InvitedTeamMembers
                    loading={invitedTeamMembers.loading}
                    data={invitedTeamMembers.data}
                    activePage={invitedTeamMembers.queryParams.activePg}
                    numPages={invitedTeamMembers.pageCount}
                  />
                </div>
                <If condition={package_types?.length && canManageAdmins}>
                  <div className="flex items-center mt-4 text-sm">
                    <Checkbox toggle onChange={toggleAutoGrantSeatsConfirm} checked={!!auto_grant_seats} ariaLabelledBy="auto-grant-text" disabled={isDemo} />
                    <p className="auto-grant-text" id="auto-grant-text">
                      Auto assign licenses to new members: {autoAssignState}
                    </p>
                  </div>
                </If>
                <If condition={canManageAdmins && !isDemo}>
                  <TeamDiscoveryDropdown domains={domains || []} editTeamSettings={editTeamSettings} autoInvite={userStore.team.auto_invite} />
                </If>
              </div>
              <FormInviteModal
                url={teamInviteUrl.url}
                loading={teamInviteUrl.loading}
                error={teamInviteUrl.error}
                invitedTeamMembers={invitedTeamMembers}
                autoGrantSeats={auto_grant_seats}
                getInvitedTeamMembers={getInvitedTeamMembers}
                getTeamInviteUrlByEmail={getTeamInviteUrlByEmail}
                triggerToast={triggerToast}
                modalOpen={state.modalOpen}
                modalClose={closeModal}
                topText={topText}
                orgId={orgId}
                bottomText={bottomText}
                omitLicenseSelection={!canManageAdmins}
                teamName={userStore.team.name}
              />
            </div>
          </div>
        </div>
        <MemberTableActions teamId={orgId} team={userStore.team} isOrgOwner={canManageAdmins} packageTypes={package_types} isDemo={isDemo} />
        <OrganizationMembersTable toggleGroupsModal={toggleGroupsModal} groupsModalOpen={state.groupsModalOpen} />
        <If condition={!isDemo}>
          <div className="border-none">
            <Header as="h2" className="mb-4 text-2xl font-black">
              Need help managing your account?
            </Header>
            <h3 className="mb-4 text-lg font-bold">Customer Success Manager</h3>
            <p className="mb-6 text-gray-600">
              <button className="text-cyb-pink-500 hover:text-black underline cursor-pointer" onClick={() => window.zE('webWidget', 'open')}>
                Contact a Customer Success Manager
                <span className="sr-only">Opens a chat window</span>
              </button>{' '}
              to manage team licenses and provide guidance and support for managing your team.
            </p>
            <h3 className="mb-4 text-lg font-bold">Knowledge Center</h3>
            <p className="text-gray-600">
              Search our{' '}
              <AddLink className="text-cyb-pink-500 hover:text-black underline" to={HELP_DESK_LINKS.CYBRARY_FOR_TEAMS} target="_blank">
                Knowledge Center
              </AddLink>{' '}
              for resources and tutorials to help learn about Cybrary for Business.
            </p>
          </div>
        </If>
        <NewTeamPurchaseMessage clearSubscriptionStatus={userStore.removeSubscriptionStatus} />
      </Container>
    );
  })
);

export default OrganizationMembers;
