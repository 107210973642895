import React from 'react';
import Container from '../../components/Container/Container';
import Header from '../../components/Header/Header';
import AddLink from '../../components/AddLink/AddLink';

import HeadersExampleSection from './ExampleComponents/HeadersExampleSection';
import WidgetContainerExampleSection from './ExampleComponents/WidgetContainerExampleSection';
import LockedContainerExampleSection from './ExampleComponents/LockedContainerExampleSection';
import ButtonsExampleSection from './ExampleComponents/ButtonsExampleSection';
import CardExampleSection from './ExampleComponents/CardExampleSection';
import LinksExampleSection from './ExampleComponents/LinksExampleSection';
import IconsExampleSection from './ExampleComponents/IconsExampleSection';
import ChipExampleSection from './ExampleComponents/ChipExampleSection';
import TableExampleSection from './ExampleComponents/TableExampleSection';
import ChartExampleSection from './ExampleComponents/ChartExampleSection';
import CountUpCardExampleSection from './ExampleComponents/CountUpCardExampleSection';
import ProgressExampleSection from './ExampleComponents/ProgressExampleSection';
import ListTableExampleSection from './ExampleComponents/ListTableExampleSection';
import UPTriggerExampleSection from './ExampleComponents/UPTriggerExampleSection';
import BasicTabsExampleSection from './ExampleComponents/BasicTabsExampleSection';
import profileUtil from '../../utils/profileUtil';

function ComponentsSideNav({ sections }) {
  return (
    <nav className="sticky top-4 self-start -ml-24">
      <h2 className="pb-2 mb-2 text-lg font-bold border-b border-gray-300">Components</h2>
      <ul className="flex flex-col gap-2 w-48">
        {sections.map((section) => (
          <li key={section.id}>
            <AddLink onClick={() => profileUtil.scrollTo(section.id)} className="hover:text-cyb-pink-500 cursor-pointer">
              {section.title}
            </AddLink>
          </li>
        ))}
      </ul>
    </nav>
  );
}

/**
 * Components page for example components
 * @returns {JSX.Element} - the components page
 */
function ComponentsPage() {
  const sections = [
    { id: 'headers', title: 'Headers', jsx: <HeadersExampleSection /> },
    { id: 'widgetContainers', title: 'Widget Containers', jsx: <WidgetContainerExampleSection /> },
    { id: 'lockedContainers', title: 'Locked Containers', jsx: <LockedContainerExampleSection /> },
    { id: 'cards', title: 'Cards', jsx: <CardExampleSection /> },
    { id: 'countUpCards', title: 'Count Up Cards', jsx: <CountUpCardExampleSection /> },
    { id: 'buttons', title: 'Buttons', jsx: <ButtonsExampleSection /> },
    { id: 'links', title: 'Links', jsx: <LinksExampleSection /> },
    { id: 'icons', title: 'Icons', jsx: <IconsExampleSection /> },
    { id: 'chips', title: 'Chips', jsx: <ChipExampleSection /> },
    { id: 'tables', title: 'Tables', jsx: <TableExampleSection /> },
    { id: 'listTables', title: 'List Tables', jsx: <ListTableExampleSection /> },
    { id: 'charts', title: 'Charts', jsx: <ChartExampleSection /> },
    { id: 'progress', title: 'Progress', jsx: <ProgressExampleSection /> },
    { id: 'basicTabs', title: 'Tabs', jsx: <BasicTabsExampleSection /> },
    { id: 'upTrigger', title: 'UPTrigger', jsx: <UPTriggerExampleSection /> },
  ].sort((a, b) => a.title.localeCompare(b.title));

  return (
    <Container size="lg" className="flex mt-5 w-full">
      <ComponentsSideNav sections={sections} />
      <div className="flex flex-col gap-4 ml-8 w-full">
        <AddLink className="hover:text-black underline" to="/">
          &laquo; Home
        </AddLink>
        <Header as="h1">Example Components</Header>
        {sections.map((section) => (
          <div key={section.id} id={section.id}>
            {section.jsx}
          </div>
        ))}
      </div>
    </Container>
  );
}

export default ComponentsPage;
