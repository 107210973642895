import React, { useMemo, useCallback, useEffect } from 'react';
import { ResponsiveRadar } from '@nivo/radar';
import Header from '../Header/Header';
import WidgetContainer from '../Container/WidgetContainer';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import { CONTENT_TYPES } from '../../constants';
import If from '../If/If';
import CircularProgress from '../ProgressBar/CircularProgress';
import FormatUtil from '../../utils/formatUtil';
import { CertificationRecentScoreBody } from '../Certifications/CertificationRecentScore';
import CareerProgramWidgetLoadingText from './CareerProgramWidgetLoadingText';
import { getTspanGroups } from '../Measure/Baseline/shared/CustomBarChartTick';

const PLACEHOLDER_DATUM = {
  topic: '',
  experience_points_earned: 0,
  experience_points_total: 0,
};

/**
 * Custom GridLabel component to be used as `ResponsiveRadar.gridLabel` prop
 * @param {GridLabelComponent} GridLabelComponentProps - passed in props from `ResponsiveRadar.gridLabel`
 * @returns
 */
function GridLabel({ id, x, y, anchor, maxLineLength = 30, maxLines = 3, fontSize = 12 }) {
  const datum = id ? JSON.parse(id) : PLACEHOLDER_DATUM;

  const title = datum.topic;

  const translateYValue = useMemo(() => {
    if (anchor === 'middle') {
      return y - Math.min(Math.round(title.length / maxLineLength), 2) * 20;
    }
    if (anchor === 'end' || anchor === 'start') {
      return y - 15;
    }

    return y;
  }, [anchor, title, y]);

  const translateXValue = useMemo(() => {
    if (anchor === 'end') {
      return x - 10;
    }
    if (anchor === 'start') {
      return x - 10;
    }

    return x;
  }, [anchor, title, x]);

  return (
    <g transform={`translate(${translateXValue}, ${translateYValue})`}>
      <text dominantBaseline="central" textAnchor={anchor} style={{ fontSize }}>
        {getTspanGroups(title, maxLineLength, maxLines)}
      </text>
    </g>
  );
}

/**
 * Custom `sliceTooltip` component for `ResponsiveRadar` chart component
 * @param {sliceProps} props
 * @returns
 */
function ChartSliceTooltip({ data, index }) {
  // stringified payload that conatins topic, experience_points_total and experience_points_earned
  const datum = index ? JSON.parse(index) : PLACEHOLDER_DATUM;

  // only data entry for chart, contains color, value, formattedValue etc.
  const dataEntry = data[0];

  return (
    <div
      style={{
        background: 'white',
        color: 'inherit',
        fontSize: 'inherit',
        borderRadius: 2,
        boxShadow: 'rgba(0, 0, 0, 0.25) 0px 1px 2px',
        padding: '5px 9px',
      }}
    >
      <div>
        <div className="flex flex-row items-center">
          <span
            className="block mr-2 w-3 h-3 rounded-full"
            style={{
              background: dataEntry.color,
            }}
          />
          <strong>{datum.topic}</strong>
        </div>
        <div className="flex flex-row items-center mt-1">
          <span className="mr-3 font-semibold">XP:</span>
          <span className="mr-3">
            {datum.experience_points_earned}/{datum.experience_points_total}
          </span>
          <span className=" text-neutral-400">({dataEntry.value}%)</span>
        </div>
      </div>
    </div>
  );
}

function CareerProgramRadarChart({ program }) {
  const { currentProgramEnrollment, careerProgramBrowseData, getCareerProgramBrowseData } = useCareerPrograms();

  const browseData = useMemo(() => careerProgramBrowseData?.[program.permalink] ?? careerProgramBrowseData?.[program.id], [careerProgramBrowseData, program]);
  useEffect(() => {
    if (program && !browseData) {
      getCareerProgramBrowseData(program.id);
    }
  }, [program]);

  const topicProgressData = useMemo(() => {
    const data = currentProgramEnrollment?.enrollments.reduce((acc, enrollment) => {
      enrollment?.content?.curriculum_items?.map((item) => {
        let topic = item.content_description.title;
        const term = item.content_description.terms_info?.find((entry) => entry.includes('Topics|'));
        const contentEnrollment = enrollment?.descendant_enrollments?.find((entry) => item.content_description_id === entry.content_description_id);

        if (term) {
          const split = term.split('|');
          const value = split[1];
          topic = value;
        }

        if (!acc[topic]) {
          acc[topic] = {
            topic,
            experience_points_earned: 0,
            experience_points_total: 0,
          };
        }
        const earnedXp = contentEnrollment?.experience_points_earned || 0;
        const totalXp = item?.content_description?.experience_points_total || 0;
        acc[topic].experience_points_earned += Math.min(earnedXp, totalXp); // cap earned xp to total xp
        acc[topic].experience_points_total += totalXp;
        return item;
      });
      return acc;
    }, {});
    return data ? Object.values(data) : null;
  }, [currentProgramEnrollment]);

  const chartData = useMemo(() => {
    if (topicProgressData) {
      return topicProgressData.map((item) => ({
        // stringify props as a clever way to pass this data to custom chart component props (sliceTooltip, gridLabel etc.),
        // this way we don't have to add extra keys to chart simply to customize tooltip
        datum: JSON.stringify({
          topic: item.topic,
          experience_points_total: item.experience_points_total,
          experience_points_earned: item.experience_points_earned,
        }),
        // percentage of xp earned to total xp
        experience_points_progress: Math.round((item.experience_points_earned / item.experience_points_total) * 100 || 0),
      }));
    }
    // Use the terms_info from the path in the program to generate the chart data
    const topics = browseData?.terms_info?.filter((term) => String(term).startsWith('Topics|'));
    return (
      topics?.map((term) => {
        const split = term.split('|');
        const topic = split[1];
        return {
          datum: JSON.stringify({
            topic,
            experience_points_total: 0,
            experience_points_earned: 0,
          }),
          experience_points_progress: 0,
        };
      }) || []
    );
  }, [topicProgressData]);

  /** Set chartConfig for different chart states */
  const chartConfig = useMemo(() => {
    const defaultConfig = {
      maxLines: 3,
      maxLineLength: 18,
      fontSize: 13,
      marginTop: 80,
      marginBottom: 80,
      marginLeft: 0,
      marginRight: 0,
      dotSize: 6,
    };
    switch (chartData?.length) {
      case 1:
      case 2:
      case 3:
        return {
          ...defaultConfig,
          maxLineLength: 30,
        };
      // 6 +
      default:
        return defaultConfig;
    }
  }, [chartData]);

  const GridLabelComponent = useCallback(
    (props) => {
      return <GridLabel {...props} maxLineLength={chartConfig?.maxLineLength} maxLines={chartConfig?.maxLines} fontSize={chartConfig?.fontSize} />;
    },
    [chartData]
  );

  return (
    <div className="relative mx-auto h-[340px]">
      <ResponsiveRadar
        animate
        data={chartData}
        keys={['experience_points_progress']}
        indexBy="datum"
        margin={{ top: chartConfig.marginTop, right: chartConfig.marginRight, bottom: chartConfig.marginBottom, left: chartConfig.marginLeft }}
        borderColor={{ from: 'color' }}
        gridLabelOffset={26}
        gridLabel={GridLabelComponent}
        sliceTooltip={ChartSliceTooltip}
        enableDots
        dotSize={chartConfig?.dotSize}
        dotColor={{ theme: 'background' }}
        dotBorderWidth={chartConfig?.dotSize} // make the dot a circle
        colors={{ scheme: 'set1' }}
        blendMode="multiply"
        motionConfig="wobbly"
        maxValue={100}
      />
    </div>
  );
}

function SkillsProgramProgressSpinner({ program }) {
  const { currentProgramEnrollment } = useCareerPrograms();
  const isActiveProgram = useMemo(() => program && program.id === currentProgramEnrollment?.content_description_id, [program, currentProgramEnrollment?.content_description_id]);

  const xpEarned = isActiveProgram ? currentProgramEnrollment?.experience_points_earned : 0;
  const xpTotal = program?.experience_points_total || 0;
  const topicProgress = isActiveProgram ? Math.round((xpEarned / xpTotal) * 100) : 0;
  const topic = useMemo(() => {
    return program?.terms_info?.find((term) => String(term).startsWith('Topics|'))?.replace('Topics|', '') || 'Topic';
  }, [program?.terms_info]);

  return (
    <div className="flex flex-col items-center space-y-4 w-full h-[260px]">
      <CircularProgress percentageValue={topicProgress} classes="w-48 h-48 font-bold text-center">
        {FormatUtil.formatNumbers(xpEarned)} / {FormatUtil.formatNumbers(xpTotal)}
        <br />
        XP Earned
      </CircularProgress>
      <p className="text-xl font-bold">{topic}</p>
    </div>
  );
}

function CertPrepProgressTracker({ certPrepContent }) {
  if (!certPrepContent) {
    return null;
  }

  return <CertificationRecentScoreBody noBorder data={certPrepContent.practiceExamScores} permalink={certPrepContent?.certificationItem?.permalink} />;
}

/**
 * Career Program Skills Tracker Widget
 * @param {CareerProgram} program - the career program
 * @returns {JSX.Element} - the skills tracker widget
 */
function CareerProgramsSkillsTrackerWidget() {
  const { isLoadingCareerProgramBrowseData, isLoadingCurrentProgram, careerProgramBrowseDataError, currentPageProgram: program, currentPageProgramCertData } = useCareerPrograms();
  const isLoading = isLoadingCareerProgramBrowseData[program?.url] || isLoadingCurrentProgram;
  const error = careerProgramBrowseDataError[program?.url];

  const title = useMemo(() => {
    switch (program?.content_type?.id) {
      case CONTENT_TYPES.CERT_PREP_PROGRAM:
        return 'Readiness Tracker';
      case CONTENT_TYPES.SKILL_PROGRAM:
      case CONTENT_TYPES.CAREER_PROGRAM:
      default:
        return 'Skills Tracker';
    }
  }, [program?.content_type?.id]);

  return (
    <WidgetContainer omitPadding>
      <Header as="h3" className="pt-4 pb-2 mx-4 mb-2 border-b border-b-gray-400">
        {title}
      </Header>
      {/** Loading */}
      <If condition={isLoading && !error}>
        <CareerProgramWidgetLoadingText className="m-4" />
      </If>
      {/** Error */}
      <If condition={!isLoading && error}>
        <p>We had an error while loading this program. Please try again later.</p>
      </If>
      <If condition={!isLoading && !error}>
        <If condition={program?.content_type?.id === CONTENT_TYPES.CAREER_PROGRAM}>
          <CareerProgramRadarChart program={program} />
        </If>
        <If condition={program?.content_type?.id === CONTENT_TYPES.SKILL_PROGRAM}>
          <SkillsProgramProgressSpinner program={program} />
        </If>
        <If condition={program?.content_type?.id === CONTENT_TYPES.CERT_PREP_PROGRAM}>
          <CertPrepProgressTracker certPrepContent={currentPageProgramCertData} />
        </If>
      </If>
    </WidgetContainer>
  );
}

export default CareerProgramsSkillsTrackerWidget;
