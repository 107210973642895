import React from 'react';
import { HELP_DESK_LINKS } from '../../constants';

export function SupportLink({ lower = false }) {
  return (
    <span>
      {lower ? 'p' : 'P'}lease contact{' '}
      <a href="mailto:support@cybrary.it" title="Email support">
        support@cybrary.it
      </a>{' '}
      or visit our{' '}
      <a href={HELP_DESK_LINKS.HOMEPAGE} target="_blank" title="Visit help center" rel="noreferrer">
        Help Center
      </a>{' '}
      for assistance.
    </span>
  );
}
/**
 * Shown when a user has successfully upgraded their account
 * @param {*} commonStore Mobx common store
 */
const showExistingAccountPurchaseSuccessToast = (commonStore) => {
  // Send success toast to give imediate feedback to user
  commonStore.triggerToast(
    'success',
    {
      header: `Purchase Successful!`,
      content: "We're upgrading your account now...",
    },
    30000
  );
};

/**
 * Shown when a user has successfully purchased while creating a new account
 * @param {*} commonStore Mobx common store
 */
const showNewAccountPurchaseSuccessToast = (commonStore) => {
  // Send success toast to give imediate feedback to user
  commonStore.triggerToast(
    'success',
    {
      header: `Purchase Successful!`,
      content: "We're creating your account now...",
    },
    30000
  );
};

/**
 * Shown when a user has successfully upgrades an existing subscription
 * @param {*} commonStore Mobx common store
 */
const showUpgradeSuccessToast = (commonStore) => {
  // Send success toast to give imediate feedback to user
  commonStore.triggerToast(
    'success',
    {
      header: `Upgrade Successful!`,
      content: "We've successfully upgraded your account to an annual subscription!",
    },
    30000
  );
};

/**
 * Shown when a user has successfully updated an existing subscription
 * @param {*} commonStore Mobx common store
 */
const showSubscriptionUpdatedToast = (commonStore) => {
  // Send success toast to give imediate feedback to user
  commonStore.triggerToast(
    'success',
    {
      header: `Subscription Updated Successfully!`,
      content: "You're all set!",
    },
    30000
  );
};

/**
 * Shown when a user has failed to upgraded their account
 * @param {*} commonStore Mobx common store
 * @param {*} error Error object
 * @param {*} data Paddle data object
 */
const showUpgradeErrorToast = (commonStore, error, data) => {
  commonStore.triggerToast(
    'error',
    {
      icon: 'face-frown',
      header: `Error upgrading your account`,
      content: (
        <>
          <p>
            Your payment was successful, but we had an error upgrading your account. <SupportLink />
          </p>
          <p>
            <span className="font-bold">Error:</span> {error.message}
            <br />
            <span className="font-bold">TXID:</span> {data?.transaction_id || 'N/A'}
          </p>
        </>
      ),
    },
    60000
  );
};

/**
 * Shown when we get an error event from paddle's iFrame
 * @param {*} commonStore Mobx common store
 * @param {*} error Error object
 */
const showEventErrorToast = (commonStore, error) => {
  // Show error toast
  commonStore.triggerToast('error', {
    icon: 'face-frown',
    header: `[${error.code}] ${error.detail}`,
    content: (
      <p>
        Sorry, we are having some trouble processing your request right now. <SupportLink />
      </p>
    ),
  });
};

/**
 * Shown when we fail to initialize the checkout due to a buggy/broken discount
 * @param {*} commonStore Mobx common store
 */
const showDiscountErrorToast = (commonStore) => {
  // Let the user know about the missing discount
  commonStore.triggerToast(
    'error',
    {
      icon: 'face-frown',
      header: 'Error Adding Discount',
      content: (
        <p>
          Sorry, try adding the discount during checkout or <SupportLink lower />
        </p>
      ),
    },
    5000
  );
};

/**
 * Shown when we fail to open the checkout after a few attempts
 * @param {*} commonStore Mobx common store
 */
const showErrorOpeningCheckoutToast = (commonStore) => {
  commonStore.triggerToast(
    'error',
    {
      icon: 'face-frown',
      header: 'Error Opening Checkout',
      content: (
        <p>
          Sorry, we are having some trouble loading the checkout. <SupportLink />
        </p>
      ),
    },
    60000
  );
};

/**
 * Shown when we fail to update the checkout
 * @param {*} commonStore Mobx common store
 */
const showErrorUpdatingCheckoutToast = (commonStore) => {
  commonStore.triggerToast(
    'error',
    {
      icon: 'face-frown',
      header: 'Error Updating Checkout',
      content: (
        <p>
          Sorry, we are having some trouble updating your checkout. <SupportLink />
        </p>
      ),
    },
    60000
  );
};

/**
 * General error toast to be used when we don't know what happened
 * @param {*} commonStore Mobx common store
 * @param {*} error Error object
 * @param {*} duration Duration in ms to show the toast
 */
const showGeneralErrorToast = (commonStore, error, duration = 10000) => {
  // Show error toast
  commonStore.triggerToast(
    'error',
    {
      icon: 'face-frown',
      header: `Error`,
      content: (
        <p>
          {error.message} <SupportLink />
        </p>
      ),
    },
    duration
  );
};

export default {
  showNewAccountPurchaseSuccessToast,
  showExistingAccountPurchaseSuccessToast,
  showUpgradeSuccessToast,
  showSubscriptionUpdatedToast,
  showUpgradeErrorToast,
  showEventErrorToast,
  showGeneralErrorToast,
  showDiscountErrorToast,
  showErrorOpeningCheckoutToast,
  showErrorUpdatingCheckoutToast,
};
