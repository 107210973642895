import React, { useEffect } from 'react';
import { twMerge } from 'tailwind-merge';
import { observer, inject } from 'mobx-react';
import { useNavigate } from 'react-router-dom';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import GaUtil from '../../utils/gaUtil';
import AuthUtil from '../../utils/authUtil';
import GradientBanner from './GradientBanner';
import EDUUpgradeBanner from './EDUUpgradeBanner';
import TwelveForSixUpgradeBanner from './TwelveForSixUpgradeBanner';
import ThreeForTwoUpgradeBanner from './ThreeForTwoUpgradeBanner';

/**
 * Determines what copy is shown based on the user goal
 * @param {String} goal user goal chosen during onboarding
 * @returns {BannerContent} { title: "", body: "" } object containing the content to show
 */
function getBannerContent(goal) {
  switch (goal) {
    case 'Launch My Career':
      return {
        title: 'Enroll In a Career Path',
        body: 'Take the next step in your cybersecurity career and sign up for Cybrary Insider Pro to unlock access to all of our career paths.',
      };
    case 'Get a Certification':
      return {
        title: 'Confidently Pass The Exam',
        body: 'Practice exams can help you improve your test scores by over 25%. Sign up for Cybrary Insider Pro and get unlimited access to practice exams and labs.',
      };
    case 'Upskill & Practice':
      return {
        title: 'Get Your Dream Role',
        body: 'Sign up for Cybrary Insider Pro to master the hands-on skills that security leaders are hiring for.',
      };
    default:
      return {
        title: `Cybrary Insider Pro`,
        body: `Upgrade to practice the hands-on skills employers are hiring for. Unlock access to practice tests, virtual labs, career paths, and much more.`,
      };
  }
}

const CIPUpgradeBanner = inject(
  'userStore',
  'profileStore',
  'commonStore'
)(
  observer(({ userStore, profileStore, commonStore, className, inline = false }) => {
    const navigate = useNavigate();

    useEffect(() => {
      profileStore.getUserAccounts();
    }, []);

    const { isEnterprise, isCip, user } = userStore;
    const { goal, membershipFor, title } = user?.onboarding_data || {};

    const { data: accounts } = profileStore?.profileData?.accounts || {};
    const isEduGovMilUser = AuthUtil.isEduGovMilUser(accounts);

    const isStudent = title === 'Student';
    const isEduGovMilPromoShown = isStudent || isEduGovMilUser;
    const isTwelveForSixPromoShown = true;
    const isThreeForTwoPromoShown = false;

    const content = getBannerContent(goal);

    // remember that a user can be free but also enterprise... (on a team but has no license)
    // we want to show for all free users who aren't cip or enterprise users
    const shouldNotShow = isCip || isEnterprise || membershipFor === 'team';
    const isInTeamsDemo = userStore?.teamData?.id === 'demo';

    useEffect(() => {
      if (!inline) {
        if (shouldNotShow) {
          commonStore.removeCybRouterClass('upgrade-banner-adjustment');
        } else {
          commonStore.addCybRouterClass('upgrade-banner-adjustment');
        }
      }
    }, [shouldNotShow]);

    if (shouldNotShow) {
      return null;
    }

    // Don't show the banner if the user is in the teams demo
    // Teams Demo has it's own banner
    if (isInTeamsDemo) {
      return null;
    }

    const handleUpgradeClick = () => {
      /**
       * This is a temporary A/B test to see if users are more likely to convert
       * if they are taken directly to the checkout page
       * 9/6/2024 - 9/9/2024
       */
      const isDirectLinkToCheckout = Math.random() < 0.5;
      if (isDirectLinkToCheckout) {
        const targetUrl = `/upgrade/checkout`;
        trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'upgradeButton', property: targetUrl });
        GaUtil.fireEvent('CIPUpgradeBanner', 'click', targetUrl);
        navigate(targetUrl);
      } else {
        const targetUrl = `${process.env.REACT_APP_V2_SITE_URL}/upgrade/`;
        trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'upgradeButton', property: targetUrl });
        GaUtil.fireEvent('CIPUpgradeBanner', 'click', targetUrl);
        window.open(targetUrl, '_blank');
      }
    };

    /** EDU MIL GOV Banner */
    if (isEduGovMilPromoShown) {
      return <EDUUpgradeBanner className={className} isEduGovMilUser={isEduGovMilUser} />;
    }

    /** Six for Twelve Banner */
    if (isTwelveForSixPromoShown) {
      return <TwelveForSixUpgradeBanner className={className} />;
    }

    /** Three for Two Banner */
    if (isThreeForTwoPromoShown) {
      return <ThreeForTwoUpgradeBanner className={className} />;
    }

    /** Default CIP Upgrade Banner - Based on Onboarding choices */
    return (
      <AddLink onClick={handleUpgradeClick} className="inline-block w-full">
        <GradientBanner className={twMerge(className, 'group')}>
          <div className="min-w-[175px] text-[24px] font-black">{content.title}</div>
          <div className="grow px-6 text-xs sm:text-lg">{content.body}</div>
          <div
            className="flex justify-center items-center py-2 px-6 mx-4 mt-2 
          max-w-[175px] text-black align-middle bg-slate-200 group-hover:bg-white rounded-sm transition-all duration-300 ease-in-out md:mt-0 md:mr-4"
          >
            Upgrade <Icon name="sparkler" className="ml-1 w-4 h-4" />
          </div>
        </GradientBanner>
      </AddLink>
    );
  })
);
export default CIPUpgradeBanner;
