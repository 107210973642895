import React, { useMemo, useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { observer, inject } from 'mobx-react';
import Container from '../../components/Container/Container';
import Title from '../../components/Title/Title';
import If from '../../components/If/If';
import SearchIcon from '../../Icons/SearchIcon';
import StyledError from '../../components/Error/StyledError';
import Loading from '../../components/Loading/Loading';
import ListTable from '../../components/ListTable/ListTable';
import { useCareerPrograms } from '../../providers/CareerProgramsProvider';
import { CONTENT_TYPES } from '../../constants';
import ActionUtil from '../../utils/actionsUtil';
import FormatUtil from '../../utils/formatUtil';

const LIST_TABLE_HEADINGS = ['program', 'domain', 'difficulty', 'XP', 'Status'];

const PROGRAM_COPY = {
  [CONTENT_TYPES.CAREER_PROGRAM]: {
    title: 'Career Paths',
    description: `Career Paths are comprehensive training programs designed to prepare you for the most in-demand roles in the cybersecurity workforce. 
    Each path follows a Learn, Practice, Prove model and includes different activity types aligned to key topics within the path’s security domain. 
    As you progress through the path, your progress will be measured in real time using Experience Points (XP) that serve as a comprehensive capability 
    score for each topic. Upon completing all of the requirements for a path, you will be rewarded with a shareable digital badge via Credly.`,
  },
  [CONTENT_TYPES.SKILL_PROGRAM]: {
    title: 'Skill Paths',
    description: `Skill Paths are learning pathways designed for learners who wish to focus their attention on a specific topic. Each path follows a 
    Learn, Practice, Prove model and includes different activity types aligned to key topics within the path’s security domain. As you progress through 
    the path, your progress will be measured in real time using Experience Points (XP) that serve as a comprehensive capability score for that topic. 
    Upon completing all of the requirements for a path, you will be rewarded with a shareable digital badge via Credly.`,
  },
  [CONTENT_TYPES.CERT_PREP_PROGRAM]: {
    title: 'Certification Prep',
    description: `Certification Prep Paths are comprehensive training programs designed to prepare you for the most in-demand certifications in 
    cybersecurity. Each path follows a Learn, Practice, Prove model and includes different activity types aligned to each domain of the exam. 
    As you progress through the path, you will have the opportunity to assess your readiness using certification-specific Practice Exams and 
    monitor your progress using the Readiness Tracker.`,
  },
  default: {
    title: 'Uh-oh...',
    description: `We couldn't find the description for this program type. Please contact support for assistance or try again later.`,
  },
};

const BrowseCareerPrograms = inject('commonStore')(
  observer(({ commonStore, contentTypeId = 0 }) => {
    const [filterQuery, setFilterQuery] = useState('');

    const { allCareerPrograms, isLoadingAllCareerPrograms, allCareerProgramsError, getAllCareerPrograms } = useCareerPrograms();

    const CURRENT_PROGRAM_COPY = PROGRAM_COPY[contentTypeId] || PROGRAM_COPY.default;

    const INPUT_NAME = FormatUtil.lowerCaseHyphenText(`${CURRENT_PROGRAM_COPY.title} Filter`).toLocaleLowerCase();

    const listTableRows = useMemo(() => {
      // Sort the programs by `orderNumber` field in ascending order. Lower order numbers come first.
      let sortedPrograms = allCareerPrograms?.sort((a, b) => {
        const aOrder = a?.meta?.orderNumber || Number.MAX_VALUE;
        const bOrder = b?.meta?.orderNumber || Number.MAX_VALUE;
        return aOrder - bOrder;
      });

      const filterQuerySanitized = filterQuery?.trim();

      if (filterQuery !== undefined && filterQuerySanitized !== '') {
        sortedPrograms = sortedPrograms?.filter((program) => {
          const { title, short_description } = program;

          const titleMatch = !!title && title.toLowerCase().includes(filterQuerySanitized.toLowerCase());

          const descriptionMatch = !!short_description && short_description.toLowerCase().includes(filterQuerySanitized.toLowerCase());

          return titleMatch || descriptionMatch;
        });
      }

      // Map the programs to the ListTable row format
      return sortedPrograms?.map((program) => {
        let statusValue = {
          key: 'status',
          value: program.status,
        };

        const releaseDate = program?.release_date ?? program?.meta?.releaseDate;

        const hasComingSoonReleaseDate = program.status === 'Coming Soon' && !!releaseDate;

        if (hasComingSoonReleaseDate) {
          statusValue = {
            ...statusValue,
            description: `(${releaseDate})`,
            href: `/browse/${program.permalink}`,
            centered: true,
            secondary: true,
          };
        }

        return {
          id: uuidv4(),
          values: [
            {
              key: 'program',
              value: program.title,
              description: program.short_description,
              image: program.thumbnail_url,
              // route for `career-programs` overview page is `browse/career-paths/*`,
              // so here we modify the passed in permalink here which uses internal naming of 'program' instead of 'path'
              href: `/browse/${program.permalink}`,
              interactive: program.status !== 'Coming Soon',
            },
            {
              key: 'domain',
              value: program.domain || 'Foundations',
            },
            {
              key: 'difficulty',
              value: program.difficulty,
            },
            {
              key: 'xp',
              value: program.experience_points_total,
            },
            statusValue,
          ],
        };
      });
    }, [allCareerPrograms, filterQuery]);

    useEffect(() => {
      // Load the data when the page is loaded
      getAllCareerPrograms(contentTypeId);
      ActionUtil.scrollToTop();
    }, [contentTypeId]);

    useEffect(() => {
      // Update page title when it changes
      commonStore.setPageTitle(`${CURRENT_PROGRAM_COPY.title} | Cybrary`);
    }, [CURRENT_PROGRAM_COPY]);

    return (
      <Container size="lg">
        <Title title={CURRENT_PROGRAM_COPY.title} />
        <div className="flex flex-col justify-between items-end mb-2 space-y-4 lg:flex-row">
          <p className="flex-1 mb-0 text-sm text-gray-600">{CURRENT_PROGRAM_COPY.description}</p>
          <div className="flex flex-row flex-1 items-center ml-20 w-full bg-gray-200 rounded-full lg:max-w-[30rem]">
            <div className="shrink ml-4">
              <SearchIcon classes="w-6 h-6 text-gray-600" />
            </div>
            <div className="grow pr-4">
              <input
                type="text"
                name={INPUT_NAME}
                placeholder="Filter results"
                className="w-full text-gray-600 placeholder:text-gray-600 bg-gray-200 border-0 focus:border-0 outline-none focus:outline-none"
                onChange={(e) => setFilterQuery(e.target.value)}
                aria-label={`Filter ${CURRENT_PROGRAM_COPY.title} results`}
                autoComplete="off"
                value={filterQuery}
              />
            </div>
          </div>
        </div>
        <If condition={isLoadingAllCareerPrograms}>
          <Container className="mt-10">
            <Loading message="Loading..." />
          </Container>
        </If>
        <If condition={allCareerProgramsError}>
          <Container className="mt-10">
            <StyledError error={allCareerProgramsError} />
          </Container>
        </If>
        <If condition={!isLoadingAllCareerPrograms && !allCareerProgramsError && !!listTableRows}>
          <ListTable headings={LIST_TABLE_HEADINGS} rows={listTableRows} />
        </If>
      </Container>
    );
  })
);
export default BrowseCareerPrograms;
