import React, { useEffect, useState } from 'react';
import LoadSpinner from '../Loading/LoadSpinner';
import CIPUpgradeBanner from '../../../Banners/CIPUpgradeBanner';

function Progress({ percent }) {
  const safePercent = percent > 100 ? 100 : percent;
  return (
    <div className="space-y-2">
      <div className="h-4 bg-neutral-600 rounded-lg">
        <div className="h-4 bg-cyb-pink-500 rounded-lg transition-all duration-500 ease-in-out" style={{ width: `${safePercent}%` }} />
      </div>
      <p className="text-sm text-center">{`${safePercent}%`}</p>
    </div>
  );
}

export default function BuildingLab() {
  const [percent, setPercent] = useState(0);
  const [seconds, setSeconds] = useState(0);

  // Right now, just guess at an estimated time of 5 minutes
  const estimatedTime = 5 * 60;

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (estimatedTime > seconds) {
        setSeconds((s) => s + 1);
      }
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    setPercent(Math.round((seconds / estimatedTime) * 100));
  }, [seconds]);

  const secondsRemaining = estimatedTime - seconds;
  const minutesRemaining = Math.floor(secondsRemaining / 60);

  let text = `Approximately ${minutesRemaining} ${minutesRemaining === 1 ? 'minute' : 'minutes'} remaining...`;
  if (minutesRemaining < 1) {
    if (secondsRemaining > 0) {
      text = `Approximately ${secondsRemaining} ${secondsRemaining === 1 ? 'second' : 'seconds'} remaining...`;
    } else {
      text = `Finishing up building your lab environment...`;
    }
  }

  return (
    <div>
      <div>
        <CIPUpgradeBanner className="mb-0" inline />
      </div>
      <div className="flex justify-center items-center h-full text-sm text-gray-100 bg-neutral-900">
        <div className="space-y-2">
          <LoadSpinner text={text} spinnerClass="h-14 w-14 text-gray-100 text-center" />
          <div className="mx-auto w-76">
            <Progress percent={percent} />
          </div>
        </div>
      </div>
    </div>
  );
}
