import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Bugsnag from '@bugsnag/js';
import Slider from 'react-slick';
import ContentCard from '../Card/ContentCard';
import ContentTransformer from '../../transformers/contentTransformer';
import Loading from '../Loading/Loading';
import DashboardWidgetTitle from './DashboardWidgetTitle';
import If from '../If/If';
import Button from '../Button/Button';

const NewReleasesWidget = inject(
  'searchStore',
  'userStore'
)(
  observer(({ searchStore, userStore }) => {
    const { searchItems } = searchStore;
    useEffect(() => {
      const getSearchItems = async () => {
        try {
          await searchStore.loadSearchItems({ categories: ['new'] });
        } catch (err) {
          Bugsnag.notify(err);
        }
      };
      getSearchItems();
    }, []);

    const { data, loading: isLoading } = searchItems;
    const { hits: newlyReleasedCourses = [] } = data?.new || {};
    const items = newlyReleasedCourses?.map((newCourse) => {
      const { id } = newCourse;
      const prepped = ContentTransformer.formatDataSources(newCourse);

      return (
        <div key={id} className="flex px-1 h-full bg-white">
          <ContentCard key={id} userStore={userStore} data={prepped} cardProps={{ menuAbove: true }} />
        </div>
      );
    });

    return (
      <>
        <DashboardWidgetTitle title="New Releases" link="/browse/refined?new=true&resultsView=grid" className="mb-4" />
        {/** Loading State */}
        <If condition={isLoading}>
          <Loading message="Loading new releases..." wrapperClassName="w-full h-4/5 my-12" />
        </If>
        {/** Default State */}
        <If condition={!isLoading && items?.length > 0}>
          <div className="w-full h-full">
            <Slider
              infinite={false}
              autoplay
              autoplaySpeed={5000}
              dots
              speed={500}
              slidesToShow={4}
              slidesToScroll={4}
              arrows={false}
              className="pb-1 mb-2"
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                  },
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 488,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {items}
            </Slider>
          </div>
        </If>
        {/** No Items State */}
        <If condition={!isLoading && items?.length === 0}>
          <p className="flex flex-col justify-center items-center pb-4 space-y-4 text-center text-gray-500 text-md">
            <span>We didn&apos;t find any new releases at this time, please check back later or...</span>
            <Button href="/browse/refined?resultsView=grid" color="pink">
              Explore All Content
            </Button>
          </p>
        </If>
      </>
    );
  })
);
export default NewReleasesWidget;
