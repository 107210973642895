import { CONTENT_TYPES } from '../../constants';

const MetricsSettings = {
  leaderboards: {
    member: {
      heading: 'Top Learners',
      href: '/reporting',
      filters: [
        {
          key: 'learning_hours',
          text: 'Learning Hours',
          value: 'learning_hours',
        },
        {
          key: 'total_xp',
          text: 'Total XP',
          value: 'total_xp',
        },
        {
          key: 'enrollments',
          text: 'Total Enrollments',
          value: 'enrollments',
        },
        {
          key: 'completed',
          text: 'Completed Enrollments',
          value: 'completed',
        },
      ],
      report: 'member-new',
    },
    content: {
      heading: 'Top Content',
      href: '/reporting/content',
      sorters: [
        {
          id: 1,
          title: 'Activity Type',
          queryParam: 'contentTypeIds',
          options: [
            {
              id: '_all', // `id` prop for default value is necessary fot use in `components/Enterprise/ComboboxMultiselect`
              key: '_all',
              label: 'All',
              value: '_all',
            },
            {
              key: 'course',
              label: 'Course',
              value: CONTENT_TYPES.COURSE,
            },
            {
              key: 'assessment',
              label: 'Assessment',
              value: CONTENT_TYPES.CYBRARY_ASSESSMENT_COURSE,
            },
            {
              key: 'virtual_lab',
              label: 'Virtual Lab',
              value: CONTENT_TYPES.CYBRARY_LAB_COURSE,
            },
            {
              key: 'challenge',
              label: 'Challenge',
              value: CONTENT_TYPES.CHALLENGE,
            },
          ],
        },
      ],
      filters: [
        {
          key: 'total_enrollments',
          text: 'Enrollments',
          value: 'enrollments',
        },
        {
          key: 'completed',
          text: 'Completed Enrollments',
          value: 'completed',
        },
      ],
    },
  },
  competencyLevels: {
    Novice: {
      color: 'rgba(87, 218, 255, 0.25)',
      range: '0% - 25%',
    },
    Intermediate: {
      color: 'rgba(87, 218, 255, 0.5)',
      range: '26% - 50%',
    },
    Advanced: {
      color: 'rgba(87, 218, 255, .75)',
      range: '51% - 75%',
    },
    Proficient: {
      color: 'rgba(87, 218, 255, 1)',
      range: '76% - 100%',
    },
    'Not Assessed': {
      color: '#eaeaea',
    },
  },
};

export default MetricsSettings;
