import React from 'react';
import { observer, inject } from 'mobx-react';
import Slider from 'react-slick';
import Tooltip from '../Tooltip/Tooltip';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import FormatUtil from '../../utils/formatUtil';
import { useCustomPathsContext } from '../../providers/CustomPathsProvider';
import Loading from '../Loading/Loading';
import If from '../If/If';
import DashboardWidgetTitle from './DashboardWidgetTitle';

const CustomPathsWidget = inject('userStore')(
  observer(({ userStore }) => {
    const { isEnterprise } = userStore;
    const { pathsData, isLoading } = useCustomPathsContext();

    /** This section should be available for all and ONLY enterprise users (even if unlicensed) */
    if (!isEnterprise) return null;

    /** we only want to display the first 10 items... */
    const trimmedPathsData = pathsData.slice(0, 10);
    /** These are the items to be displayed inside the Carousel */
    const CarouselItems = trimmedPathsData?.map((customPath) => {
      const { id, name, team_id } = customPath;
      /** if more then 35 characters truncate our text */
      const formattedName = FormatUtil.formatLongText(name, 55);
      const nameClasses = 'font-bold text-sm xs:text-base';

      return (
        <Tooltip key={id} content={name} position="bottom">
          <AddLink to={`/custom-paths/${team_id}/${id}`} aria-labelledby={`${id}-name`}>
            <div className="flex flex-row gap-4 items-center p-4 mx-2 h-16 bg-white rounded-sm border-1 border-gray-500">
              <Icon name="path" className="shrink-0 w-8 h-8" />
              <p className={nameClasses}>{formattedName}</p>
            </div>
            <p id={`${id}-name`} className="sr-only">
              {name}
            </p>
          </AddLink>
        </Tooltip>
      );
    });

    return (
      <>
        <DashboardWidgetTitle title="Custom Paths" link="/custom-paths/all" className="lg:mb-4" />
        {/** Loading State */}
        <If condition={isLoading}>
          <Loading message="Loading your custom paths..." wrapperClassName="w-full h-4/5 my-12" />
        </If>
        {/** Default State */}
        <If condition={!isLoading && CarouselItems?.length > 0}>
          <div className="w-full h-full">
            <Slider
              infinite={false}
              autoplay
              autoplaySpeed={5000}
              dots
              speed={500}
              slidesToShow={3}
              slidesToScroll={3}
              arrows={false}
              className="pb-2 mb-2"
              responsive={[
                {
                  breakpoint: 1024,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                  },
                },
                {
                  breakpoint: 767,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                  },
                },
              ]}
            >
              {CarouselItems}
            </Slider>
          </div>
        </If>
        {/** No Items State */}
        <If condition={!isLoading && CarouselItems?.length === 0}>
          <p className="flex justify-center items-center min-h-[200px] text-center text-gray-500 text-md">No Custom Paths to display</p>
        </If>
      </>
    );
  })
);
export default CustomPathsWidget;
