import React, { useEffect } from 'react';
import { observer, inject } from 'mobx-react';
import Loading from '../Loading/Loading';
import Table from '../Table/Table';
import StyledError from '../Error/StyledError';
import AdminTable from '../AdminTable/AdminTable';
import Dropdown from '../Dropdown/Dropdown';
import DynamicForm from '../DynamicForm/DynamicForm';
import { getRoleOptions } from '../../utils/teamsUtil';
import './admin.css';

const searchForm = {
  order: ['search', 'submit'],
  fields: {
    search: {
      type: 'text',
      placeholder: 'Search by name, email, or ID',
      description: 'Minimum of 4 characters required for search',
    },
    submit: {
      type: 'button',
      color: 'pink',
      icon: 'search',
      label: 'Search',
    },
  },
};

function MemberTable({ loading, error, data, tableSort, pagChangePg, addToTeamConfirm }) {
  if (data.loading === null) {
    // On initial load, before user searches, don't want anything to display, including the loading icon
    return null;
  }

  if (loading) {
    return (
      <div>
        <Loading message="Loading..." />
      </div>
    );
  }
  if (error) {
    return (
      <div>
        <StyledError error={error} />
      </div>
    );
  }

  if (!data.totalRecords) {
    return <p className="no-results">No results found</p>;
  }

  const getCustomTableCol = (tableData) => {
    return (
      <Table.Cell>
        <Dropdown
          placeholder="Choose Role"
          options={getRoleOptions(true)}
          onChange={(e) => addToTeamConfirm(e, { memberid: tableData[0].value })}
          onBlur={false}
          classes="change-role-dropdown text-xs"
        />
      </Table.Cell>
    );
  };

  return (
    <AdminTable
      /* Table Data */
      headings={data.tableHeadings}
      data={data.tableData}
      tableLoading={loading}
      tableError={error}
      displayCheckBox={false}
      headerClickFunc={tableSort}
      sortCol={data.queryParams.sortCol}
      sortDirection={data.queryParams.sortDirection}
      addCustomCol={[
        {
          method: getCustomTableCol,
          position: data.tableHeadings ? data.tableHeadings.length : 9,
          header: 'Add to team as',
        },
      ]}
      className="four column"
      colClasses="text-xs"
      /* Pagination options */
      pagNumPgs={data.pagPagesCount}
      pagChangePg={pagChangePg}
      pagActivePg={data.queryParams.activePg}
      omitResponsive
    />
  );
}

const CybraryAdminAddMembers = inject(
  'adminStore',
  'commonStore'
)(
  observer(({ adminStore, commonStore, team, addFunc }) => {
    useEffect(() => {
      return () => {
        adminStore.setDefaultMemberSearchTableData();
      };
    }, []);

    const tableSort = (heading) => {
      if (heading) {
        if (heading === adminStore.memberSearchTableData.queryParams.sortCol) {
          adminStore.setMemberSearchTableParams('sortDirection', adminStore.memberSearchTableData.queryParams.sortDirection === 'desc' ? 'asc' : 'desc');
        } else {
          adminStore.setMemberSearchTableParams('sortCol', heading);
          adminStore.setMemberSearchTableParams('sortDirection', 'desc');
        }
        adminStore.setMemberSearchTableParams('activePg', 1);
        adminStore.getMemberSearchTableData(team);
      }
    };

    const pagChangePg = (pg) => {
      adminStore.setMemberSearchTableParams('activePg', pg);
      adminStore.getMemberSearchTableData(team);
    };

    const handleSubmit = (data) => {
      if (!!data && !!data.search && data.search.length) {
        adminStore.setMemberSearchTableParams('activePg', 1);
        adminStore.searchMembers(team, data.search);
      }
    };

    const addCancel = () => {
      commonStore.resetConfirmState();
    };

    const addToTeam = (role, memberid) => {
      adminStore
        .addMemberToTeam(team, role, memberid)
        .then(() => {
          addFunc('success');
        })
        .catch(() => {
          addFunc('error');
        });
      addCancel();
    };

    const addToTeamConfirm = (e, { memberid }) => {
      const { value } = e;

      commonStore.triggerConfirm({
        content: 'Are you sure you want to add this user to the team?',
        cancel: () => addCancel(),
        continue: () => {
          addToTeam(value, memberid);
        },
      });
    };

    const checkFieldLength = (data) => {
      return data.search && data.search.length > 3 ? null : 'Search must include at least 4 characters';
    };

    const addUsersForm = { ...searchForm };
    addUsersForm.fields.search.validations = [checkFieldLength];

    return (
      <div className="admin-add-members">
        <DynamicForm form={addUsersForm} formName="searchMemberForm" onSubmit={handleSubmit} />
        <MemberTable
          loading={adminStore.memberSearchTableData.loading}
          error={adminStore.memberSearchTableData.error}
          data={adminStore.memberSearchTableData}
          tableSort={tableSort}
          pagChangePg={pagChangePg}
          addToTeamConfirm={addToTeamConfirm}
        />
      </div>
    );
  })
);

export default CybraryAdminAddMembers;
