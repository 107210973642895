import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Userpilot } from 'userpilot';
import Icon from '../Icon/Icon';
import AddLink from '../AddLink/AddLink';
import { trackSnowplowEvent } from '../../utils/snowplowUtil';
import { PADDLE_DISCOUNT_ID_CIP_TWELVE_FOR_SIX, USERPILOT_EVENTS } from '../../constants';
import GradientBanner from './GradientBanner';

function TwelveForSixUpgradeBanner({ className }) {
  const navigate = useNavigate();
  const handleUpgradeClick = () => {
    const targetUrl = `/upgrade/checkout?discountId=${PADDLE_DISCOUNT_ID_CIP_TWELVE_FOR_SIX}&plan=annual&lockPlan=1`;
    trackSnowplowEvent({ category: 'CIPUpgradeBanner', action: 'click', label: 'upgradeButtonTwelveForSix', property: targetUrl });
    navigate(targetUrl);
    Userpilot.track(USERPILOT_EVENTS.CIP_BANNER_CLICKED_UPGRADE);
  };

  return (
    <AddLink onClick={handleUpgradeClick} className="inline-block w-full">
      <GradientBanner className={`${className} relative overflow-hidden group px-0 md:px-6`}>
        <div className="flex flex-col justify-between items-center w-full align-middle lg:flex-row">
          <Icon name="unlock" className="hidden left-4 z-10 w-16 h-16 transition-all duration-300 group-hover:scale-105 group-hover:-rotate-12 fill-white lg:block" />
          <div className="z-20 grow px-0 text-sm md:px-6 md:text-lg">
            <div className="flex flex-col">
              <div className="md:whitespace-nowrap">
                October Special: <span className="text-xl group-hover:border-b-2">Get 12 months of access</span> for the price of 6!
              </div>
              <div className="font-normal">
                Celebrate Cybersecurity Awareness Month with our <span className="text-xl font-bold group-hover:border-b-2">50% OFF</span> offer.
              </div>
              <span className="flex justify-center items-center py-0 text-xs italic font-normal align-middle whitespace-nowrap">
                Valid until October 31.
                <Icon name="growth" className="mx-2 w-4 h-4 fill-white" /> Elevate your skills today!
              </span>
            </div>
          </div>
          <AddLink
            onClick={handleUpgradeClick}
            className="flex z-20 items-center p-1 px-28 mx-auto mt-2 text-black bg-slate-100 group-hover:bg-slate-200 rounded-sm lg:p-2 lg:px-6 lg:mt-0"
          >
            Upgrade
          </AddLink>
        </div>
      </GradientBanner>
    </AddLink>
  );
}

export default TwelveForSixUpgradeBanner;
