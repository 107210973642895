import React, { useMemo } from 'react';
import { twMerge } from 'tailwind-merge';
import Container from '../Container/Container';

function GradientBanner({ className, children }) {
  const hoverClasses = 'transition transition-all duration-[4000ms] ease-in-out bg-[length:110%_110%] hover:bg-[length:200%_200%] bg-left-bottom hover:bg-right-bottom';
  const classes = useMemo(
    () =>
      twMerge(
        `group p-1 sm:p-2 mx-auto w-full text-white bg-gradient-to-r from-[#CF0469] to-[#25135F] font-bold min-h-[80px] flex item-center max-h-[25vh] overflow-hidden`,
        className,
        hoverClasses
      ),
    [className]
  );
  return (
    <div className={classes}>
      <Container size="xl-1680" className="items-center px-0 text-center md:flex" omitPadding>
        {children}
      </Container>
    </div>
  );
}

export default GradientBanner;
